<template>
  <form @submit.prevent="handleFormSubmit">
  <div class="row" style="">
    <div class="col-md-5">
      <div class="sg-img">
        <!-- Tab panes -->
        <div class="tab-content">
          <template v-for="(v, k) in products" >
          <div :id="'sg'+k" class="tab-pane " :class="getActive(k)" role="tabpanel">
            <img :src="v.media" class="img-fluid" alt="">
          </div>
          </template>
        </div>
        <div class="nav d-flex flex-row">
          <template v-for="(v, k) in products" >
            <a class="thumbs nav-item nav-link " :class="getActive('a'+k)" data-toggle="tab" :href="'#sg'+k"><img
                :src="v.media" @click="updateProduct(k)" :alt="v.title"></a>
          </template>
        </div>
        <!--Close Tab panes -->
      </div>
    </div>
    <div class="col-md-7">
      <div class="sg-content">
        <div class="pro-tag">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item"><a href="">Home Appliance</a></li>
            <li class="list-inline-item"><a href="">Kitchen</a></li>
          </ul>
        </div>
        <div class="pro-name">
          <h3 style="font-size: 1.2em;" v-html="product.title"></h3>
<!--          <h6 style="font-style: italic;" v-html="product.description"></h6>-->
        </div><br/>
        <div class="pro-rating">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item"><i class="fa fa-star"></i></li>
            <li class="list-inline-item"><i class="fa fa-star"></i></li>
            <li class="list-inline-item"><i class="fa fa-star"></i></li>
            <li class="list-inline-item"><i class="fa fa-star"></i></li>
            <li class="list-inline-item"><i class="fa fa-star-o"></i></li>
            <li class="list-inline-item"><a href="">( 09 Review )</a></li>
          </ul>
        </div>
        <hr/>
        <div class="pro-price">
          <ul class="list-unstyled list-inline">
            <input type="text" class="hidden vPrice" v-text="product.price" >
            <li class="list-inline-item v_price_li">$ <span class="v_price" v-text="price"></span></li>
            <li class="list-inline-item ">$<span class="v_pre_price" v-text="product.price"></span></li>
          </ul>
          <p>Availability : <span class="v_stock">In stock
                                                <label  v-if="product.stock">({{ product.stock }} Available)</label>
                                                <label class="not" v-else>(Not Available)</label>
                                            </span></p>
        </div>
        <div class="colo-siz" >
            <div class="variants color" v-for="(value, key, index) in product.variants" :key="key">
              <ul class="list-unstyled list-inline"   v-if="key === 'Color'" >
                <li>Color :</li>
                <li class="list-inline-item" v-for="(color, k, i) in value" :key="k">
                  <input class="variant" type="radio" :id="'color-'+k" name="color" :value="color" @click="variantChange" v-model="colors">

                  <label :for="'color-'+k" style="border-radius: 50%; border: 1px solid #aaa;">
                  <span class="" :style="{backgroundColor: color }">
                      <i style="color: #aae;" class="fa fa-check"></i></span></label>
                </li>
              </ul>
            </div>

            <div class="variants size" v-for="(value, key, index) in product.variants" :key="key">
              <ul class="list-unstyled list-inline"  v-if="key === 'Size'" >
                <li>Size :</li>
                <li class="list-inline-item" v-for="(v, k, i) in value" :key="k">
                  <input class="variant" type="radio" :id="'size-'+k" name="size" :value="v" @click="variantChange" v-model="sizes">
                  <label :for="'size-'+k" style="border-radius: 5px; width: auto; padding: 5px;">{{ v }}</label>
                </li>
              </ul>
            </div>

          <div class="qty-box">
            <ul class="list-unstyled list-inline">
              <li class="list-inline-item">Qty :</li>
              <li class="list-inline-item quantity buttons_added">
                <input type="button" value="-" class="minus" @click="updateQty(-1)">
                <input type="text" :value="qty" @change="updateQty($event.target.value, true)"  class="qty v_qty text" size="5" style="min-width: 60px;">
                <input type="button" value="+" class="plus" @click="updateQty(+1)">
              </li>
            </ul>
          </div>
          <div class="pro-btns">
            <input class="selectProduct" type="text" :value="product.price">
            <a  class="btn btn-round btn-cart cart" @click="addCart">
              <span class="load"></span> <img src="/images/media/cart-white.svg" width="20"> Add to Cart </a>
            <a href="" class="fav-com" data-toggle="tooltip" data-placement="top" title="" data-original-title="Wishlist"><img src="/images/media/it-fav.png" alt=""></a>
            <button type="submit" @click="up">ok</button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      price: {{product.price}}
      <div v-html="product.description"></div>
      {{colors}}/{{sizes}}
    </div>
  </div>
  </form>
</template>

<script>
import axios from "axios";

import {async} from "regenerator-runtime";

import {resolveTransitionHooks} from "vue";
import {assertObject} from "@babel/core/lib/config/validation/option-assertions";

const router = VueRouter.cerate

export default {
  name: "Cart",
  data(){
    return {
      qty : 1,
      price: 0,
      product: [],
      order: {productId: 0, qty:0 },
      currentUrl: window.location.href,
      colors : [],
      sizes : [],

    }
  },

  methods:{
    getActive(index){
      if(index == 0)
        return 'fade active show';
      else if(index == 'a0')
        return 'active';
      else
        return "";
    },
    addCart(){

      // alert('check');
      const obj = {quantity: this.qty, id: this.product.id};
      console.log(this.getCartUrl());
     // const response =
         axios.post(this.getCartUrl(), obj)
          .then((response) => {
            console.log(response.data[0]);
            if(!response.data[0]){
              this.$router.push('http://alifcart.test/');
            }else{
              console.log('ok');
            }
          }).catch(error => {
            console.log(error.message);
         })
     ;

      // console.log(response);
    },
    getCartUrl(){
      return this.currentUrl.replace('item', 'shop/addcart');
    },
    updateProduct(k){
      this.product = this.products[k];
      this.updateQty(this.qty, true);

    },
    updateQty(v, flag){
      if(flag)
        this.qty = parseInt(v);
      else
       this.qty = this.qty + v;
      this.qty = (this.qty < 1)? 1 : this.qty;

      if(this.qty > this.product.stock)
        this.qty = parseInt(this.product.stock);
      this.price = (parseFloat( this.product.price)) * parseFloat(this.qty);
      return this.qty;
    },

    handleFormSubmit(e){
      console.log('submit')
    },
    getProductMedia(media){
      return '/upload/media/'+ media
    },
    getProductUrl(){
     return this.currentUrl.replace('item', 'product')
    },
    variantChange(index){
      // let names = document.getSelection()
      // this.product.price = '10';
      let title = this.colors+'/'+this.sizes;
      // this.setProduct(this.variants[title]);
      this.product = this.variants[title].price;
      this.stock = this.variants[title].stock;
    },
    // setProduct(product){
    //   this.product = product.price;
    //   this.stock = product.stock
    // }
  },
  props: {
    products: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    // axios
    // .get(this.getProductUrl())
    // .then((response) => {
      this.updateProduct(0);
    // })

    // console.log(this.product);

  }

}

</script>

<style scoped>
.thumbs{margin-right: 2px;}
</style>